import React from 'react';
import { Link, useSearchParams } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { LangBlock } from "./index";
import { useSelector } from "react-redux";

function OffersHeader() {
	const { user, isLogined } = useSelector(state => state.auth)
	const pathname = window.location.pathname.split('/')[1]
	const [searchParams] = useSearchParams();
	const params = searchParams.toString()

	return (
		<header className="offers__header">
			<div className="container">
				<div className="header__wrapper d-flex justify-content-between">
					<Link to={`${params ? `/?${params}` : '/'}`} className='logo'>
						<img src={logo} alt="logo" />
					</Link>
					{isLogined && pathname !== 'noregoffers' &&
						<div className='user'>
							<div className='user__name'>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<path
										d="M16.6667 17.5V15.8333C16.6667 14.9493 16.3155 14.1014 15.6904 13.4763C15.0653 12.8512 14.2174 12.5 13.3334 12.5H6.66671C5.78265 12.5 4.93481 12.8512 4.30968 13.4763C3.68456 14.1014 3.33337 14.9493 3.33337 15.8333V17.5"
										stroke="#6D74C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									<path
										d="M9.99996 9.16667C11.8409 9.16667 13.3333 7.67428 13.3333 5.83333C13.3333 3.99238 11.8409 2.5 9.99996 2.5C8.15901 2.5 6.66663 3.99238 6.66663 5.83333C6.66663 7.67428 8.15901 9.16667 9.99996 9.16667Z"
										stroke="#6D74C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
								{user?.firstName} {user?.lastName}
							</div>
							{/*<div className='edit__user'>*/}
							{/*    <Button className='outlined'>*/}
							{/*        <span>{t('general.changePassword')}</span>*/}
							{/*        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
							{/*            <g clipPath="url(#clip0_432_14374)">*/}
							{/*                <path d="M9.16669 3.33398H3.33335C2.89133 3.33398 2.4674 3.50958 2.15484 3.82214C1.84228 4.1347 1.66669 4.55862 1.66669 5.00065V16.6673C1.66669 17.1093 1.84228 17.5333 2.15484 17.8458C2.4674 18.1584 2.89133 18.334 3.33335 18.334H15C15.442 18.334 15.866 18.1584 16.1785 17.8458C16.4911 17.5333 16.6667 17.1093 16.6667 16.6673V10.834" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
							{/*                <path d="M15.4167 2.08417C15.7482 1.75265 16.1978 1.56641 16.6667 1.56641C17.1355 1.56641 17.5852 1.75265 17.9167 2.08417C18.2482 2.41569 18.4345 2.86533 18.4345 3.33417C18.4345 3.80301 18.2482 4.25265 17.9167 4.58417L10 12.5008L6.66669 13.3342L7.50002 10.0008L15.4167 2.08417Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
							{/*            </g>*/}
							{/*            <defs>*/}
							{/*                <clipPath id="clip0_432_14374">*/}
							{/*                    <rect width="20" height="20" fill="white"/>*/}
							{/*                </clipPath>*/}
							{/*            </defs>*/}
							{/*        </svg>*/}
							{/*    </Button>*/}
							{/*    <button className='log-out'>*/}
							{/*        <span>{t('general.logOut')}</span>*/}
							{/*        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
							{/*            <path d="M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5" stroke="#6D74C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
							{/*            <path d="M13.3333 14.1673L17.5 10.0007L13.3333 5.83398" stroke="#6D74C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
							{/*            <path d="M17.5 10H7.5" stroke="#6D74C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
							{/*        </svg>*/}
							{/*    </button>*/}
							{/*</div>*/}

						</div>
					}

					{window.location.pathname !== '/policy' ?
						<LangBlock />
						:
						''
					}

				</div>
			</div>
		</header>
	);
}

export default OffersHeader;