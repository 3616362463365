import React, { useEffect, useState } from 'react';
import Button from "../Button";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountFormFilled, fetchCreditInfo } from "../../redux/slices/mainSlice";
import Accordion from 'react-bootstrap/Accordion';

const activeCreditOptions = {
	none: { text: 'general.noLoans' },
	mortgage: { text: 'general.mortgage' },
	consumer: { text: 'general.consumerLoan' },
	auto: { text: 'general.carLoan' },
	creditCard: { text: 'general.creditCard' },
	microCredit: { text: 'general.microLoan' }
}

const overdueCreditOptions = {
	none: { text: 'general.noDelays' },
	was: { text: 'general.wereInThePast' },
	max30: { text: 'general.upTo30Days' },
	max90: { text: 'general.upTo90Days' },
	max180: { text: 'general.upTo180Days' },
	more180: { text: 'general.moreThan180Days' }
}

const ModalStepTwo = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch()
	const { visitHash, loading } = useSelector(state => state.main)
	const [checkboxError, setCheckboxError] = useState(false)
	const [activeCredit, setActiveCredit] = useState(['none'])
	const [overdueCredit, setOverdueCredit] = useState('none')

	const {
		register,
		handleSubmit,
		watch
	} = useForm({
		mode: 'onSubmit',
		defaultValues: {
			activeCredit: ['none'],
			overdueCredit: 'none'
		}
	})


	useEffect(() => {
		const subscription = watch((value) => {
			setActiveCredit(value.activeCredit)
			setOverdueCredit(value.overdueCredit)
		})
		return () => subscription.unsubscribe()
	}, [watch])


	const onSubmitAbout = (data) => {
		setCheckboxError(false)
		if (data.activeCredit.length === 0) {
			return setCheckboxError(true)
		}
		const preparedData = {
			...data,
			activeCredit: data.activeCredit.join(','),
			visitHash
		}
		dispatch(fetchCountFormFilled(3))
		dispatch(fetchCreditInfo(preparedData))
	}
	return (
		<>
			<div className="body-title">
				<h1>{t('general.lastStep')}</h1>
			</div>
			<form onSubmit={handleSubmit(onSubmitAbout)}>
				<label className='fw-semibold'>{t('general.doYouHaveActiveLoans')}</label>
				<div className="input__block">
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								{activeCredit.length === 0 ? (
									<p>{t('general.selectAtLeastOne')}</p>
								) : (
									<label className='d-flex align-items-baseline' role='button'>
										<input
											className='w-auto'
											type='checkbox'
											{...register('activeCredit')}
											value={activeCredit[0]}
										/>
										<span className='ms-2'>{t(activeCreditOptions[activeCredit[0]]?.text)}</span>
									</label>
								)}
							</Accordion.Header>
							<Accordion.Body>
								{Object.keys(activeCreditOptions)
									.sort((a, b) => {
										const aChecked = activeCredit.includes(a);
										const bChecked = activeCredit.includes(b);
										if (aChecked && !bChecked) {
											return -1;
										} else if (!aChecked && bChecked) {
											return 1;
										} else {
											return 0;
										}
									})
									.map((credit) => {
										return credit === activeCredit[0] ? null : (
											<label className='d-flex align-items-baseline' role='button' key={credit}>
												<input
													className='w-auto'
													type='checkbox'
													{...register('activeCredit')}
													value={credit}
												/>
												<span className='ms-2'>{t(activeCreditOptions[credit]?.text)}</span>
											</label>
										);
									})}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>

				<label className='fw-semibold'>{t('general.doYouHaveAnyDelays')}</label>
				<div className="input__block">
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								<label className='d-flex align-items-baseline pe-none' role='button'>
									<input
										className='w-auto'
										type='radio'
										value={overdueCredit}
										defaultChecked={true}
									/>
									<span className='ms-2'>{t(overdueCreditOptions[overdueCredit].text)}</span>
								</label>
							</Accordion.Header>
							<Accordion.Body>
								{Object.keys(overdueCreditOptions).map((option) => {
									return option === overdueCredit ? null : (
										<label key={option} className='d-flex align-items-baseline' role='button'>
											<input
												className='w-auto'
												type='radio'
												{...register('overdueCredit')}
												value={option}
											/>
											<span className='ms-2'>{t(overdueCreditOptions[option]?.text)}</span>
										</label>
									)
								})}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
				<Button className={`outlined mt4 ${loading ? 'disabled' : ''}`}>
					{t('general.getAnOffer')}
				</Button>
			</form>
		</>
	);
};

export default ModalStepTwo;