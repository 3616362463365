import React from 'react';
import { DontGoModal, OffersBlock, OffersFooter, OffersHeader } from "../components";


function TieraOffers() {
	return (
		<>
			<OffersHeader />
			<OffersBlock />
			<OffersFooter />
			{/*<Popup />*/}
			<DontGoModal/>
		</>
	);
}

export default TieraOffers;