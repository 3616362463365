import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from "../../api/api";

const initialState = {
	jwt: '',
	phoneNumber: '',
	visitHash: '',
	clientHash: '',
	applicationId: '',
	visitStartedAt: '',
	queryParams: '',
	formStep: 0,
	loading: false,
	redirectRules: {
		noRegLandUrl: '',
		regLandUrl: ''
	}
}

export const fetchVisits = createAsyncThunk(
	'main/fetchVisits',
	async (params, { rejectWithValue, dispatch, getState }) => {
		try {
			const { data } = await api.visits(params)

			const { visitHash, clientHash } = data
			const visitStartedAt = Math.floor(Date.now() / 1000).toString()
			const state = getState().main

			if (!state.clientHash) {
				dispatch(setClientHash(clientHash))
			}

			// if query parameters have changed => update hash and start visit time
			if (state.queryParams !== window.location.search.substring(1)) {
				dispatch(setVisitStartedAt(visitStartedAt))
				dispatch(setVisitHash(visitHash))
				dispatch(setQueryParams(window.location.search.substring(1)))
			}

			if (state.visitStartedAt && state.visitHash) {
				const lastVisitTime = state.visitStartedAt
				const currentTime = Math.floor(Date.now() / 1000)

				// visit hash updates after 7 days
				if (currentTime - lastVisitTime > 604800) {
					dispatch(setVisitStartedAt(visitStartedAt))
					dispatch(setVisitHash(visitHash))
				}
			} else {
				dispatch(setVisitStartedAt(visitStartedAt))
				dispatch(setVisitHash(visitHash))
			}

			return data
		} catch (err) {
			if (!err.response) {
				throw err
			}
			return rejectWithValue(err.response)
		}
	}
)


export const fetchSendPhone = createAsyncThunk(
	'main/fetchApplications',
	async (params, { rejectWithValue, getState, dispatch }) => {
		const state = getState().main
		if (state.phoneNumber) {
			try {
				if (!state.applicationId) {
					const { data } = await api.sendPhone({ visitHash: state.visitHash, phone: state.phoneNumber })
					const { applicationId } = data
					dispatch(setApplicationId(applicationId))

					return data
				} else {
				}
			} catch (err) {
				if (!err.response) {
					throw err
				}
				return rejectWithValue(err.response)
			}
		}
	}
)

export const fetchUsersData = createAsyncThunk(
	'main/fetchUsersData',
	async (params, { rejectWithValue, getState, dispatch }) => {
		const state = getState().main
		// temporary changes
		const linkReg = state.redirectRules.regLandUrl ? `${state.redirectRules.regLandUrl}` : '/tieraoffers'
		try {
			const { data } = await api.sendUsersData(params, state.applicationId)
			await dispatch(fetchCountFormFilled(2)) // temporary changes
			params.reset()
			dispatch(setFormStep(0))

			// temporary changes
			localStorage.setItem('isLogined', '1')
			window.location.href = `${linkReg}${state.queryParams && `?${state.queryParams}`}`

			return data
		} catch (err) {
			if (!err.response) {
				throw err
			}
			return rejectWithValue(err.response)
		}
	}
)

export const fetchEndVisit = createAsyncThunk(
	'main/fetchEndVisit',
	async (params, { rejectWithValue, getState }) => {
		const state = getState().main
		try {
			const { data } = await api.visitEnded(state.visitHash)
			return data
		} catch (err) {
			if (!err.response) {
				throw err
			}
			return rejectWithValue(err.response)
		}
	}
)

export const fetchCreditInfo = createAsyncThunk(
	'main/fetchCreditInfo',
	async (params, { rejectWithValue, dispatch, getState }) => {
		const state = getState().main
		const linkReg = state.redirectRules.regLandUrl ? `${state.redirectRules.regLandUrl}` : '/tieraoffers'
		try {
			const res = await api.sendCreditInfo(params, state.applicationId)

			if (res.status === 200) {
				dispatch(setFormStep(0))
				localStorage.setItem('isLogined', '1')

				// reset current user to get actual from /me on the offers page
				localStorage.setItem('user', JSON.stringify({
					firstName: '',
					lastName: '',
					score: res.data.preScore || 0,
					scoreNum: 0
				}))
				window.location.href = `${linkReg}${state.queryParams && `?${state.queryParams}`}`
			}

			return res

		} catch (err) {
			if (!err.response) {
				throw err
			}
			return rejectWithValue(err.response)
		}
	}
)

export const fetchRedirectRules = createAsyncThunk(
	'main/fetchRedirectRules',
	async (params, { rejectWithValue, dispatch, }) => {
		try {
			const { data } = await api.redirectRules(params)
			dispatch(setRedirectRules({
				noRegLandUrl: data[0].noRegLandUrl,
				regLandUrl: data[0].regLandUrl
			}))
			return data

		} catch (err) {
			if (!err.response) {
				throw err
			}
			return rejectWithValue(err.response)
		}
	}
)

export const fetchCountFormFilled = createAsyncThunk(
	'main/fetchCountFormFilled',
	async (params, { rejectWithValue, getState }) => {
		const { main } = getState()

		const obj = {
			visitHash: main.visitHash,
			number: params
		}


		try {
			await api.countFormFilled(obj, main.applicationId)
		} catch (err) {
			if (!err.response) {
				throw err
			}
			return rejectWithValue(err.response)
		}
	}
)

export const mainSlice = createSlice({
	name: 'main',
	initialState,
	reducers: {
		setPhone(state, action) {
			state.phoneNumber = action.payload
		},
		setClientHash(state, action) {
			state.clientHash = action.payload
		},
		setVisitHash(state, action) {
			state.visitHash = action.payload
		},
		setQueryParams(state, action) {
			state.queryParams = action.payload
		},
		setVisitStartedAt(state, action) {
			state.visitStartedAt = action.payload
		},
		setJwt(state, action) {
			state.jwt = action.payload
		},
		setApplicationId(state, action) {
			state.applicationId = action.payload
		},
		setFormStep(state, action) {
			state.formStep = action.payload
		},
		setLoading(state, action) {
			state.loading = action.payload
		},
		setRedirectRules(state, action) {
			state.redirectRules = action.payload
		}
	},
	extraReducers(builder) {
		builder
			.addCase(fetchUsersData.fulfilled, (state) => {
				state.loading = false
			})
			.addCase(fetchUsersData.pending, (state) => {
				state.loading = true
			})
			.addCase(fetchUsersData.rejected, (state, action) => {
				state.codeStatus = action.error.message
				state.loading = false
			})
			.addCase(fetchSendPhone.fulfilled, (state) => {
				state.loading = false
			})
			.addCase(fetchSendPhone.pending, (state) => {
				state.loading = true
			})
			.addCase(fetchSendPhone.rejected, (state) => {
				state.loading = false
			})
			.addCase(fetchCreditInfo.fulfilled, (state) => {
				state.loading = false
			})
			.addCase(fetchCreditInfo.pending, (state) => {
				state.loading = true
			})
			.addCase(fetchCreditInfo.rejected, (state) => {
				state.loading = false
			})
	}

})


export const {
	setPhone,
	setClientHash,
	setQueryParams,
	setVisitStartedAt,
	setVisitHash,
	setJwt,
	setApplicationId,
	setFormStep,
	setRedirectRules
} = mainSlice.actions

export default mainSlice.reducer