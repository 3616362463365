import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersData } from "../../redux/slices/mainSlice";
import { Link } from "react-router-dom";

const ModalStepOne = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { visitHash, loading } = useSelector(state => state.main)

	const {
		register: registerActivePhone,
		formState: {
			errors
		},
		reset,
		watch,
		handleSubmit,
		setFocus,
		setValue
	} = useForm({
		mode: 'onChange',
	})


	const onSubmitAll = (data) => {
		delete data['agreement']
		dispatch(fetchUsersData({ ...data, visitHash, reset }))
	}

	const handleTrimValue = (e, name) => {
		setValue(name, e.target.value.trim())
	}

	// set focus when modal opens
	useEffect(() => {
		setFocus('firstName')
	}, [setFocus])


	return (
		<>
			<div className="body-title">
				<h1>{t('general.letsGetAcquainted')}</h1>
			</div>

			<form onSubmit={handleSubmit(onSubmitAll)}>
				<label htmlFor="firstName" className='fw-semibold'>{t('general.whatIsYourName')}</label>
				<div className="input__block required">
					<input
						id="firstName"
						placeholder={t('general.name')}
						value={watch('firstName')?.trim() || ''}
						onChange={(e) => {
							handleTrimValue(e, 'firstName')
						}}
						{...registerActivePhone('firstName', {
							required: t('general.invalidFormat'),
							pattern: {
								value: /^[a-z а-я]+$/i,
								message: t('general.invalidFormat')
							},
						})}
					/>
					<div className='error'>{errors?.firstName &&
						<p>{errors?.firstName?.message}</p>}</div>
				</div>
				<div className="input__block required">
					<input
						placeholder={t('general.surname')}
						value={watch('lastName')?.trim() || ''}
						onChange={(e) => handleTrimValue(e, 'lastName')}
						{...registerActivePhone('lastName', {
							required: t('general.invalidFormat'),
							pattern: {
								value: /^[a-z а-я]+$/i,
								message: t('general.invalidFormat')
							}
						})}
					/>
					<div className='error'>{errors?.lastName &&
						<p>{errors?.lastName?.message}</p>}</div>
				</div>
				{/*<label className='mt-2 fw-semibold' htmlFor="birthday">{t('general.dateOfBirth')}</label>*/}
				{/*<div className="input__block required">*/}
				{/*	<input*/}
				{/*		max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split('T')[0]}*/}
				{/*		type='date'*/}
				{/*		min='1950-01-01'*/}
				{/*		id='birthday'*/}
				{/*		{...registerActivePhone('birthday', {*/}
				{/*			required: t('general.required'),*/}
				{/*			pattern: {*/}
				{/*				value: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,*/}
				{/*				message: t('general.invalidFormat')*/}
				{/*			}*/}
				{/*		})}*/}
				{/*	/>*/}
				{/*	<div className='error'>{errors?.birthday &&*/}
				{/*		<p>{errors?.birthday?.message}</p>}</div>*/}
				{/*</div>*/}
				{/*<label htmlFor="email" className='fw-semibold'>{t('general.whereToSendAnOffer')}</label>*/}
				{/*<div className="input__block required">*/}
				{/*	<input placeholder={t('general.email')}*/}
				{/*		   id='email'*/}
				{/*		   value={watch('email')?.trim() || ''}*/}
				{/*		   onChange={(e) => handleTrimValue(e, 'email')}*/}
				{/*		   {...registerActivePhone('email', {*/}
				{/*			   required: t('general.invalidFormat'),*/}
				{/*			   pattern: {*/}
				{/*				   value: /^(([^<>()[\].,;:@"]+(\.[^<>()[\].,;:@"]+)*)|(".+"))@(([^<>()[\].,;:@"]+\.)+[^<>()[\].,;:@"]{2,}\s*)$/i,*/}
				{/*				   message: t('general.invalidFormat')*/}
				{/*			   }*/}
				{/*		   })}*/}
				{/*	/>*/}
				{/*	<div className='error'>{errors?.email && <p>{errors?.email?.message}</p>}</div>*/}
				{/*</div>*/}


				<div className="checkbox__block">
					<label
						className='d-flex align-items-baseline align-items-lg-center justify-content-center'>
						<input type="checkbox"
							   {...registerActivePhone('agreement', {
								   required: t('general.submitAgreement')
							   })}
						/>
						<span>{t('general.agreementP1')}</span>
						<Link to='/policy' target="_blank"
							  rel="noopener noreferrer">{t('general.agreementP2')}</Link>
					</label>
					<div className='error'>{errors?.agreement &&
						<p>{errors?.agreement?.message}</p>}</div>
				</div>
				<Button className={`outlined ${loading ? 'disabled' : ''}`}>
					{t('general.findOutTheRating')}
				</Button>
			</form>
		</>
	);
};

export default ModalStepOne;