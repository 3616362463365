import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Footer, Header, ModalComponent, PreFooter, SliderBlock } from "../components";
import {
	fetchCountFormFilled,
	fetchRedirectRules,
	fetchSendPhone,
} from "../redux/slices/mainSlice";
import { fetchMe } from "../redux/slices/authSlice";

import { useSearchParams, useNavigate } from "react-router-dom";


import "rc-slider/assets/index.css";

import textIcon from '../assets/images/icons/file-text.svg'
import briefcaseIcon from '../assets/images/icons/la_briefcase.svg'
import moneyIcon from '../assets/images/icons/carbon_money.svg'


function MainPage() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	// const accordionArr = accordionTranslatorAdapter()
	const { isLogined } = useSelector(state => state.auth)
	const [searchParams] = useSearchParams();
	const {
		formStep,
		applicationId
	} = useSelector(state => state.main)

	const params = searchParams.toString()

	const [show, setShow] = useState(false);

	const handleClose = () => {
		// if user close not filled form popup - send a request
		if (formStep) {
			dispatch(fetchCountFormFilled(formStep))
		}
		setShow(false)
	}
	const handleShow = () => setShow(true);
	// when click back button - redirect to noregoffers
	useEffect(() => {

		const listener = () => {
			navigate(`${params ? `/noregoffers/?${params}` : '/noregoffers'}`)
		}

		window.history.pushState('/', '', `${params ? `/?${params}` : '/'}`)
		window.addEventListener('popstate', listener)

		return () => {
			window.removeEventListener('popstate', listener)
		}
	}, [navigate, searchParams, params])


	// if user didn't fill all forms - open current popup with form
	useEffect(() => {
		if (!isLogined) {
			if (formStep === 1) {
				dispatch(fetchSendPhone())
				handleShow()
			} else if (formStep === 2) {
				handleShow()
			}
		}
	}, [isLogined, dispatch])

	// fetch user data
	useEffect(() => {
		dispatch(fetchMe())

	}, [dispatch])

	// save utm source if it is enabled in url
	useEffect(() => {
		const utm = searchParams.get('utm_source')
		if (utm) {
			dispatch(fetchRedirectRules(utm))
		}
	}, [searchParams, dispatch])

	// if user didn't fill form during 10 minutes - send request
	useEffect(() => {
		if (show && formStep) {
			const timeout = setTimeout(() => {
				dispatch(fetchCountFormFilled(formStep))
			}, 600000)

			return () => clearTimeout(timeout)
		}
	}, [show, formStep, dispatch])

	// handle event when user leaves inner height of the page
	useEffect(() => {
		function handleWindowOut(event) {
			// make this request only if modal is open and there is application id
			if (show && applicationId) {
				if (event.clientY <= 0 || event.clientY >= window.innerHeight) {
					dispatch(fetchCountFormFilled(formStep))
				}
			}
		}

		document.addEventListener("mouseleave", handleWindowOut);

		return () => {
			document.removeEventListener("mouseleave", handleWindowOut);
		}
	}, [applicationId, show, formStep, dispatch])

	return (
		<>
			<Header />

			<div className="main">
				<section className="top">
					<div className="container">
						<div className="top__wrapper align-items-xl-center">
							<SliderBlock firstInput={true} handleShow={handleShow} title={t('main.top.title')} />
						</div>
					</div>
				</section>
				<section className="getLoan">
					<div className="container">
						<div className="getLoan__wrapper">
							<div className='title'>
								<h2 className="title__top">
									{t('main.getLoan.title')}
								</h2>
								<p className="title__text">
									{t('main.getLoan.text')}
								</p>
							</div>
							<div className='steps'>
								<div className="step">
									<div className="step__num">
										1
									</div>

									<div className="info">
										<div className="info__icon">
											<img src={textIcon} alt="textIcon" />
										</div>
										<div className="info__text">
											{t('main.getLoan.step1')}
										</div>
									</div>
								</div>

								<div className="step">
									<div className="step__num">
										2
									</div>

									<div className="info">
										<div className="info__icon">
											<img src={briefcaseIcon} alt="briefcaseIcon" />
										</div>

										<div className="info__text">
											{t('main.getLoan.step2')}
										</div>
									</div>
								</div>

								<div className="step">
									<div className="step__num">
										3
									</div>

									<div className="info">
										<div className="info__icon">
											<img src={moneyIcon} alt="moneyIcon" />
										</div>


										<div className="info__text">
											{t('main.getLoan.step3')}
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</section>

				<section className="advantages">
					<div className="container">
						<div className="advantages__wrapper">
							<h2 className="title__top">
								{t('main.advantages.title')}
							</h2>
							<div className='advantages__info'>
								<ul className='list'>
									<li className='list__item'>
										{t('main.advantages.list1')}
									</li>
									<li className='list__item'>
										{t('main.advantages.list2')}
									</li>
									<li className='list__item'>
										{t('main.advantages.list3')}
									</li>
								</ul>
								<p>{t('main.advantages.text')}</p>
							</div>
						</div>
					</div>
				</section>

				{/*<section className="questions" >*/}
				{/*    <div className="container">*/}
				{/*        <div className="questions__wrapper">*/}
				{/*            <div className='title'>*/}
				{/*                <h2 className="title__top">*/}
				{/*                    {t('main.questions.title')}*/}
				{/*                </h2>*/}
				{/*                <p className="title__text">*/}
				{/*                    {t('main.questions.text')}*/}
				{/*                </p>*/}
				{/*            </div>*/}

				{/*            <Accordion defaultActiveKey="0">*/}
				{/*                {accordionArr.map((item, i) => {*/}
				{/*                    return <Accordion.Item key={i} eventKey={i.toString()}>*/}
				{/*                        <Accordion.Header>{item.header}</Accordion.Header>*/}
				{/*                        <Accordion.Body>*/}
				{/*                            <div className='body__wrapper'>{item.body}</div>*/}
				{/*                        </Accordion.Body>*/}
				{/*                    </Accordion.Item>*/}
				{/*                })}*/}
				{/*            </Accordion>*/}
				{/*        </div>*/}
				{/*    </div>*/}
				{/*</section>*/}

				<section className="top service">
					<div className="container">
						<div className="top__wrapper bottom align-items-xl-center">
							<SliderBlock handleShow={handleShow} title={t('main.bottom.title')}
										 service={t('main.bottom.service')} />
						</div>
					</div>
				</section>
			</div>

			<PreFooter />
			<Footer />
			<ModalComponent show={show} handleClose={handleClose} />
		</>
	);
}

export default MainPage;