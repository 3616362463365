import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from "../../api/api";
import { setJwt } from "./mainSlice";
import { generateRandomNumber } from "../../utils/generateRandomNumber";


const initialState = {
	isLogined: localStorage.getItem('isLogined') || '',
	user: {
		firstName: JSON.parse(localStorage.getItem(('user')))?.firstName || '',
		lastName: JSON.parse(localStorage.getItem(('user')))?.lastName || '',
		score: JSON.parse(localStorage.getItem(('user')))?.score || 4,
		scoreNum: JSON.parse(localStorage.getItem(('user')))?.scoreNum || 65,
	},
	loginSmsStatus: '',
	verifySmsStatus: '',
	isLoadingLogin: false,
}

export const fetchMe = createAsyncThunk(
	'main/fetchMe',
	async (params, { rejectWithValue, dispatch, getState }) => {
		const { main } = getState()
		const { auth } = getState()
		try {
			const { data } = await api.me(main?.visitHash)
			dispatch(setJwt(data.jwt))

			if (data.authClient) {
				// make generatedNum once and save it not to change it on every page reload
				const scoreNum = !auth.user.scoreNum ? generateRandomNumber(data.authClient.score || auth.user.score) : auth.user.scoreNum
				const newUser = {
					firstName: data.authClient.lastApplication?.firstName,
					lastName: data.authClient.lastApplication?.lastName,
					score: !auth.user.score ? data.authClient.score : auth.user.score,
					scoreNum
				}
				dispatch(setUser(newUser))
				localStorage.setItem('user', JSON.stringify(newUser))
			}

			return data

		} catch (err) {
			if (!err.response) {
				throw err
			}
			return rejectWithValue(err.response)
		}
	}
)

export const fetchLoginSms = createAsyncThunk(
	'main/fetchLoginSms',
	async (params, { rejectWithValue, dispatch }) => {
		try {
			dispatch(setLoginSmsStatus(''))
			const { data } = await api.sendLoginSms(params)

			if (data.message === 'Sms send error') {
				dispatch(setLoginSmsStatus('error'))
			} else if (data.message === 'Sms sent') {
				dispatch(setLoginSmsStatus('success'))
			}

			return data
		} catch (err) {
			if (!err.response) {
				throw err
			}
			return rejectWithValue(err.response)
		}
	}
)

export const fetchVerifyLoginSms = createAsyncThunk(
	'main/fetchVerifyLoginSms',
	async (params, { rejectWithValue, dispatch, getState }) => {
		const state = getState().main
		const linkReg = state.redirectRules.regLandUrl ? `${state.redirectRules.regLandUrl}` : '/tieraoffers'
		try {
			dispatch(setVerifySmsStatus(''))
			const { data } = await api.verifyLoginSms(params)

			if (data.status === 'error') {
				dispatch(setVerifySmsStatus('error'))
			} else if (data.status === 'success') {
				dispatch(setVerifySmsStatus('success'))
				dispatch(setLoginSmsStatus(''))
				localStorage.setItem('isLogined', '1')
				window.location.href = `${linkReg}${state.queryParams && `?${state.queryParams}`}`
			}

			return data
		} catch (err) {
			if (!err.response) {
				throw err
			}
			return rejectWithValue(err.response)
		}
	}
)


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setLoginSmsStatus(state, action) {
			state.loginSmsStatus = action.payload
		},
		setVerifySmsStatus(state, action) {
			state.verifySmsStatus = action.payload
		},
		setUser(state, action) {
			state.user = action.payload
		},
		setIsLogined(state, action) {
			state.isLogined = action.payload
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchLoginSms.fulfilled, (state) => {
				state.isLoadingLogin = false
			})
			.addCase(fetchLoginSms.pending, (state) => {
				state.isLoadingLogin = true
			})
			.addCase(fetchLoginSms.rejected, (state) => {
				state.isLoadingLogin = false
			})
			.addCase(fetchVerifyLoginSms.fulfilled, (state) => {
				state.isLoadingLogin = false
			})
			.addCase(fetchVerifyLoginSms.pending, (state) => {
				state.isLoadingLogin = true
			})
			.addCase(fetchVerifyLoginSms.rejected, (state) => {
				state.isLoadingLogin = false
			})
	}

})


export const {
	setLoginSmsStatus,
	setVerifySmsStatus,
	setUser,
	setIsLogined,
} = authSlice.actions

export default authSlice.reducer