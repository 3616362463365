import {Route, Routes} from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {MainPage, NoregOffers, NotFound, Policy, TieraOffers, TierbOffers} from "./pages";
import {initTranslations} from "./utils/initTranslations";
import { fetchEndVisit, fetchVisits } from "./redux/slices/mainSlice";

import qs from "qs";
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/app.scss'


initTranslations()

function App() {
    const dispatch = useDispatch()
    const { clientHash } = useSelector(state => state.main)

    const fetchOnUnload = useCallback(async () => {
        dispatch(fetchEndVisit())
    }, [dispatch])

    useEffect(() => {
        const path = window.location.pathname
        const params = {
            "clientHash": "",
            "requestDetails": {
                "queryParameters": {}
            },
            "path": path
        }
        if (window.location.search) {
            params.requestDetails.queryParameters = qs.parse(window.location.search.substring(1))
        }

        if (clientHash) {
            params.clientHash = clientHash
        }

        dispatch(fetchVisits(params))

    }, [dispatch])



    useEffect(() => {
        window.addEventListener('beforeunload', fetchOnUnload)
        return () => {
            window.removeEventListener('beforeunload', fetchOnUnload)
        }
    }, [fetchOnUnload])

    return (
        <>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/noregoffers" element={<NoregOffers />} />
                <Route path="/tieraoffers" element={<TieraOffers />} />
                <Route path="/tierboffers" element={<TierbOffers />} />
                <Route path="/policy" element={<Policy />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}

export default App;
