import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Navbar } from "react-bootstrap";
import { Link } from "react-scroll";

import logo from './../assets/images/logo.png'
import { LangBlock } from "./index";
import LoginModal from "./modals/LoginModal";


function Header() {

    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>

            <header className="header">
                <Navbar expanded={expanded} collapseOnSelect expand="md">
                    <div className='container'>
                        <Link className='logo' to='/'>
                            <img src={logo} alt="logo" />
                        </Link>
                        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}
                                       aria-controls="responsive-navbar-nav">
                            <div aria-controls="responsive-navbar-nav" className='burger'>
                                <span />
                            </div>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <div className="menu__block d-flex flex-column flex-md-row align-items-center">
                                <ul className="menu d-flex flex-column flex-md-row">
                                    <li onClick={handleShow} className="menu__list">
                                        {t('general.privateCabinet')}
                                    </li>
                                </ul>
                                <LangBlock expanded={expanded} setExpanded={setExpanded} />
                            </div>

                        </Navbar.Collapse>
                    </div>


                </Navbar>
            </header>
            <LoginModal show={show} handleClose={handleClose}/>
        </>
    );
}

export default Header;