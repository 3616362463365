import React from 'react';
import { DontGoModal, OffersBlock, OffersFooter, OffersHeader } from "../components";

function NoregOffers() {



    return (
        <>
            <OffersHeader />
            <OffersBlock />
            <OffersFooter />
            {/*<Popup/>*/}

            <DontGoModal/>
        </>
    );
}

export default NoregOffers;