import React from 'react';
import classNames from "classnames";

function Button({children, onClick, className, type, ...attrs}) {
    const classes = classNames(
        'button',
        className,
    )

    return (
        <button
            {...attrs}
            className={classes}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    );
}

export default Button;
