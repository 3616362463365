import React from 'react';
import { OffersBlock, OffersFooter, OffersHeader } from "../components";

function TierbOffers() {
    return (
        <>
            <OffersHeader />
            <OffersBlock/>
            <OffersFooter/>
            {/*<Popup/>*/}
        </>
    );
}

export default TierbOffers;