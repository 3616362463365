import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import mainReducer from "./slices/mainSlice";
import authReducer from "./slices/authSlice";


const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    blacklist: ['auth', 'main']
}

const mainPersistConfig = {
    key: 'main',
    storage,
    version: 1,
    blacklist: ['loading', 'redirectRules']
}


const rootReducer = combineReducers({
        main: persistReducer(mainPersistConfig, mainReducer),
        auth: authReducer
    }
)

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export const persistor = persistStore(store)
export default store