import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Button } from "./index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HOST } from "../api/apiUrls";

import 'swiper/scss';
import 'swiper/scss/navigation';


function SwiperSlider({ data }) {
	const { t } = useTranslation();
	const { visitHash, applicationId } = useSelector(state => state.main)
	const { isLogined } = useSelector(state => state.auth)

	return (
		<Swiper
			modules={[Navigation]}
			spaceBetween={50}
			slidesPerView={1}
			loop={true}
			className='body__offers'
			navigation={true}
		>

			{data.map(item => (
				<SwiperSlide key={item.id}>
					<div className="offer">
						<div className="offer__logo">
							<img src={item.logo} alt="logo" />
						</div>
						<div className="offer__amount">
							до <span>{item.amount}</span> ₸
						</div>
						<a target='_blank' rel="noreferrer"
						   href={`${HOST}offer/${isLogined ? `a/${applicationId}/` : `${visitHash}/`}1`}>
							<Button className="offer__button outlined">
								{t('general.getMoney')}
							</Button>
						</a>
					</div>
				</SwiperSlide>
			))}
		</Swiper>

	);
}

export default SwiperSlider;