import React from 'react';
import {useTranslation} from "react-i18next";

const languages = ['kz', 'ru']

function LangBlock({expanded, setExpanded}) {
    const {i18n} = useTranslation();
    const onClickHandler = (item) => {
        i18n.changeLanguage(item)
        localStorage.setItem('lang', item)
        if (expanded) {
            setExpanded(false)
        }
    }

    return (
        <div className='lang'>
            {
                languages.map((item, i) => {
                    return <span key={i} onClick={() => onClickHandler(item)} className={`lang__item ${item === i18n.language ? 'active' : ''}`}>{item}</span>
                })
            }
        </div>
    );
}

export default LangBlock