import React, { useEffect, useState } from 'react';
import indicator from './../assets/images/indicator.svg'
import indicatorLine from './../assets/images/indicator-line.svg'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const IndexHealthCard = () => {
	const { t } = useTranslation()
	const { score, scoreNum } = useSelector(state => state.auth.user)
	const [deg, setDeg] = useState(0)

	useEffect(() => {
		switch (score) {
			case 1:
				setDeg(-102)
				break
			case 2:
				setDeg(-65)
				break
			case 3:
				setDeg(-22)
				break
			case 4:
				setDeg(22)
				break
			case 5:
				setDeg(65)
				break
			case 6:
				setDeg(102)
				break
			default:
				setDeg(0)
		}
	}, [score])

	return (
		<div className='indexHealthCard'>
			<div className='indexHealthCard__tooltip'>{t('general.financialHealthIndex')}</div>
			<div className='indexHealthCard__body'>
				<div className='indicator__block'>
					<img src={indicator} alt="indicator" />
					<span>
						<img style={{ transform: `rotateZ(${deg}deg)` }} className='indicator-line' src={indicatorLine}
							 alt="indicatorLine" />
					</span>
				</div>
				<div className='subscribe'>
					<h4 className='subscribe__title'>{t('general.subscribeInformation')}</h4>
					<p className='subscribe__info'>{t('general.subscribeIsActive')}</p>
					<p className='index__num'>
						{t('general.yourIndex')}: <span>{scoreNum}</span>
					</p>
					{/*<Button className='outlined subscribe__btn'>{t('general.extend')}</Button>*/}
					{/*<button className='unsubscribe__btn'>{t('general.unsubscribe')}</button>*/}
				</div>
			</div>
		</div>
	);
};

export default IndexHealthCard;