import React, { useEffect, useState } from 'react';
import { Offer } from "./index";
import offersData from '../offersData/offersData.json'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getIndexForHealthCard } from "../utils/getIndexForHealthCard";
import { fetchMe, setIsLogined } from "../redux/slices/authSlice";
import { Navigate } from "react-router-dom";
import { setApplicationId } from "../redux/slices/mainSlice";


function OffersBlock() {
	const { t, i18n } = useTranslation();
	const { language } = i18n
	const dispatch = useDispatch()
	const { isLogined } = useSelector(state => state.auth)
	const pathname = window.location.pathname.split('/')[1]
	const [data, setData] = useState(offersData[pathname])

	// add IndexHealthCard to data
	useEffect(() => {
		if (isLogined && pathname !== 'noregoffers') {
			const index = getIndexForHealthCard(offersData[pathname])
			setData([...offersData[pathname].slice(0, index), { id: 'index' }, ...offersData[pathname].slice(index)])
		}
	}, [pathname, isLogined])


	useEffect(() => {
		window.addEventListener('storage', () => {
			const reg = localStorage.getItem('isLogined')
			const applicationId = JSON.parse(localStorage.getItem('persist:main')).applicationId.replace(/"/g, '')
			dispatch(setIsLogined(reg))
			dispatch(setApplicationId(applicationId))
		})
	}, [dispatch]);

	useEffect(() => {
		if (pathname !== 'noregoffers') {
			dispatch(fetchMe())
		}
	}, [pathname, dispatch])

	if (!isLogined && pathname !== 'noregoffers') {
		return <Navigate to="/" replace={true} />
	}

	return (
		<>
			<section className="offers">
				<div className="container">
					<div className="offers__wrapper">
						<div className="title__block">
							<h1>{t('offers.title')}</h1>
							<p>{t('offers.text')}</p>
						</div>
						<div className="offers__block">

							{
								data.map((item) => (
									<Offer isLogined={isLogined} key={item.id} item={item} />
								))
							}

						</div>
					</div>
				</div>
			</section>

			<section className='offers__info'>
				<div className='container'>
					<div className="info">
						<h2 className="info__title">
							{t('offers.info')}
						</h2>
						<ul className="list">
							
							{offersData[pathname].map((item) => {
								return item.info[language] && <li key={item.id} className="list__item">{item.info[language]}</li>
							})}


						</ul>

						<div className="info__bottom">
							*{t('offers.note')}
						</div>
					</div>
				</div>
			</section>
		</>


	);
}

export default OffersBlock;