import React from 'react';
import { Amount } from "./index";
import { useTranslation } from "react-i18next";


function SliderBlock({ title, service, firstInput }) {
    const { t } = useTranslation();

    return (
        <>
            <Amount firstInput={firstInput} />

            <div className="about">
                <h1>
                    {title}
                </h1>

                {
                    !service ?
                        <>
                            <ul>
                                <li>
                                    {t('main.top.list1')}
                                </li>
                                <li>
                                    {t('main.top.list2')}
                                </li>
                                <li>
                                    {t('main.top.list3')}
                                </li>
                            </ul>
                        </>
                        :
                        <p>
                            <span>Tateshka</span> - {service}
                        </p>
                }


            </div>


            <div className="image" />
        </>
    );
}

export default SliderBlock;