import React from 'react';
import {Link} from "react-scroll";
import logo from "../assets/images/logo.png";
import {useTranslation} from "react-i18next";

function PreFooter() {
    const {t} = useTranslation();

    return (
        <section className="preFooter">
            <div className="container">
                <div className="preFooter__wrapper d-flex justify-content-between flex-column flex-md-row">
                    <div className="left">
                        <Link to='top' className="left__logo">
                            <img src={logo} alt="logo" />
                        </Link>
                        <p className='left__text'>
                            <Link to="top">Tateshka.kz</Link> - {t('main.preFooter.text')}
                        </p>
                    </div>

                    <ul className="right">
                        <li><Link to="getLoan">{t('main.preFooter.list1')}</Link></li>
                        <li><Link to="advantages">{t('main.preFooter.list2')}</Link></li>
                        {/*<li><Link to="questions">{t('main.preFooter.list3')}</Link></li>*/}
                        <li><Link to="service">{t('main.preFooter.list4')}</Link></li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default PreFooter;