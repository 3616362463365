import React from 'react';
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const params = searchParams.toString()
	function createMarkup() {
		return { __html: t('main.footer.info') }
	}

	return (
		<footer className="footer">
			<div className="container">
				<div
					className="footer__wrapper d-flex flex-column flex-md-row justify-content-between align-items-center">
					<a href="mailto:info@credit.kz">info@tateshka.kz</a>
					<p dangerouslySetInnerHTML={createMarkup()} />
					<Link to={`${params ? `/policy/?${params}` : '/policy'}`}>{t('general.policy')}</Link>
				</div>
			</div>
		</footer>
	);
}

export default Footer;