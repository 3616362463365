export const getIndexForHealthCard = (data) => {
	const windowWidth = window.innerWidth
	let index = 0
	if (windowWidth > 1200) {
		if (data.length < 4) {
			index = data.length
		}  else {
			index = 3
		}
	}

	if (windowWidth < 1200 && windowWidth > 767) {
		if (data.length < 3) {
			index = data.length
		} else {
			index = 2
		}
	}

	return index
}
