import axios from "axios";
import { API_KEY, HOST } from "./apiUrls";

const instance = (url, contentType = "application/json", visitHash = '') => {
    return axios.create({
        headers: {
            "Content-Type": contentType,
            "X-API-Key": `${API_KEY}${visitHash ? `.${visitHash}` : ''}`,
            "accept": "application/json",
        },
        baseURL: url
    })
}


export const api = {
    me(visitHash) {
        return instance(HOST, "application/json", visitHash).get('api/me')
    },
    visits(data) {
        return instance(HOST).post('api/visits', data)
    },
    visitEnded(visitHash) {
        return instance(HOST).put(`api/visits/${visitHash}`, { visitEndedAt: new Date().toJSON() })
    },
    sendPhone(obj) {
        return instance(HOST).post(`api/applications`, obj)
    },
    sendUsersData(obj, applicationId) {
        return instance(HOST).put(`api/applications/${applicationId}`, obj)
    },
    sendLoginSms(bodyFormData) {
        return instance(HOST, "multipart/form-data").post(`api/applicationClient/login/sendSms`, bodyFormData)
    },
    verifyLoginSms(bodyFormData) {
        return instance(HOST, "multipart/form-data").post(`api/applicationClient/login/verifySms`, bodyFormData)
    },
    sendCreditInfo(obj, applicationId) {
        return instance(HOST).put(`api/application/${applicationId}/credit/`, obj)
    },
    redirectRules(utm) {
        return instance(HOST).get(`api/redirect_rules?page=1&itemsPerPage=1&utmSource=${utm}`)
    },
    countFormFilled(data, applicationId) {
        return instance(HOST).post(`api/applications/action/trigger/form_filled/${applicationId}/`, data)
    }
}

