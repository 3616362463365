import React from 'react';
import { Link, useSearchParams } from "react-router-dom";
import logo from "../assets/images/logo.png";
import {useTranslation} from "react-i18next";

function OffersFooter() {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const params = searchParams.toString()

    return (
        <footer className="offers__footer">
            <div className="container">
                <div className="footer__wrapper">
                    <Link to={`${params ? `/?${params}` : '/'}`} className="footer__logo">
                        <img src={logo} alt="logo" />
                    </Link>
                    <a href="mailto:info@credit.kz" className="footer__link">info@credit.kz</a>
                    <Link className='footer__policy' to={`${params ? `/policy/?${params}` : '/policy'}`}>{t('general.policy')}</Link>
                </div>
            </div>
        </footer>
    );
}

export default OffersFooter;