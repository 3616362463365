import React, { useEffect, useRef } from 'react';
import { Button } from "../components";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationId, setFormStep, setPhone } from "../redux/slices/mainSlice";


function Amount({firstInput}) {
	const { t } = useTranslation();
	const dispatch = useDispatch()
	const inputRef = useRef(null);
	const { jwt, visitHash, queryParams, redirectRules } = useSelector(state => state.main)

	// const [value, setValue] = useState(20000)

	// const setCurrentSliderValue = (num) => {
	//     setValue(num)
	// }
	const defaultValues = {
		number: ""
	}

	const {
		formState: {
			errors
		},
		handleSubmit,
		control,
	} = useForm({
		mode: 'onSubmit',
		defaultValues,
	})

	const onSubmit = (data) => {
		const phoneNumber = data.number.replace(/[ +()]/g, '')
		const linkNoReg = redirectRules.noRegLandUrl ? `${redirectRules.noRegLandUrl}` : '/noregoffers'

		dispatch(setFormStep(1))
		dispatch(setPhone(phoneNumber))
		dispatch(setApplicationId(''))

		localStorage.setItem('isLogined', '')

		setTimeout(() => {
			window.open(`/${queryParams && `?${queryParams}`}`, '_blank')
		}, 100)
		setTimeout(() => {
			window.location.href = `${linkNoReg}${queryParams && `?${queryParams}`}`
		}, 300)

	}

	// focus first input when page loaded
	useEffect(() => {
		if (firstInput) {
			inputRef.current.focus()
		}
	}, [firstInput]);

	return (
		<div className="amount d-flex align-items-center flex-column">
			<h3>
				{t('general.tellAboutYourSelf')}
			</h3>
			{/*<div className='slider'>*/}
			{/*    <div className='slider__sum d-flex justify-content-between'>*/}
			{/*        <span>{value} ₸</span>*/}
			{/*        <span>250 000 ₸</span>*/}
			{/*    </div>*/}
			{/*    <Slider defaultValue={20000}*/}
			{/*            min={5000}*/}
			{/*            max={250000}*/}
			{/*            step={1000}*/}
			{/*            onChange={setCurrentSliderValue} />*/}
			{/*</div>*/}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="number"
					rules={{
						pattern: {
							value: /^\+7 \((6|7)\d{2}\) \d{3} \d{2} \d{2}/,
							message: '+7 (6xx) xxx-xx-xx, +7 (7xx) xxx-xx-xx'
						},
						required: t('general.required')
					}}
					control={control}
					render={({ field: { onChange, value } }) => (
						<InputMask mask="+7 (999) 999 99 99" maskChar=" " value={value} onChange={onChange}>
							{(inputProps) => (
								<input
									ref={inputRef}
									placeholder={t('general.enterNumber')}
									{...inputProps}
									type="tel"
									className="input"
								/>
							)}
						</InputMask>
					)}
				/>
				<div className='error'>{errors?.number && <p>{errors?.number?.message}</p>}</div>
				<Button type='submit' className={`outlined ${!jwt || !visitHash ? 'disabled' : ''}`}>
					{t('general.getMoney')}
				</Button>

			</form>

		</div>
	);
}

export default Amount;