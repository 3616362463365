import React from 'react';
import {OffersFooter, OffersHeader} from "../components";

const NotFound = () => {
    return (
        <>
            <OffersHeader/>
                <div className="main">
                    <div className="container">
                        Sorry, this page is not found 404.
                    </div>
                </div>
            <OffersFooter/>

        </>
    );
};

export default NotFound;