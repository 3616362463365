import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { Button, SwiperSlider } from "../index";

import offersData from '../../offersData/dontGoModalOffersData.json'
import { HOST } from "../../api/apiUrls";
import { useSelector } from "react-redux";

const pathname = window.location.pathname.split('/')[1]
const data = offersData[pathname]


function DontGoModal() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const { visitHash, applicationId } = useSelector(state => state.main)
    const { isLogined } = useSelector(state => state.auth)

    const handleClose = () => {
        setShow(false)
    }

    function getWindowDimensions() {
        const { innerWidth: width } = window
        return {
            width
        }
    }


    function handleWindowOut(event) {
        if (event.clientY <= 0 || event.clientY >= window.innerHeight) {
            setShow(true)
        }
    }


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        document.addEventListener("mouseleave", handleWindowOut);
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
            document.removeEventListener("mouseleave", handleWindowOut);
        }
    }, [])


    return (
        <Modal backdrop="static" className='dontGoModal' centered size='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton />
            <Modal.Body>
                <h2 className="body__title">
                    {t('dontGoModal.title')}
                </h2>
                <div className="body__text">
                    {t('dontGoModal.text')}
                </div>

                {windowDimensions.width > 575 ?
                    <div className='body__offers'>

                        {data.map(item => (
                            <a key={item.id} target='_blank' rel="noreferrer"
                               href={`${HOST}offer/${isLogined ? `a/${applicationId}/` : `${visitHash}/`}${item.id}`}>
                                <div className="offer">
                                    <div className="offer__logo">
                                        <img src={item.logo} alt="logo" />
                                    </div>
                                    <div className="offer__amount">
                                        до <span>{item.amount}</span> ₸
                                    </div>
                                    <Button className="offer__button outlined">
                                        {t('general.getMoney')}
                                    </Button>
                                </div>
                            </a>
                        ))}


                    </div>
                    :
                    <SwiperSlider data={data} />
                }


            </Modal.Body>
        </Modal>
    );
}

export default DontGoModal;