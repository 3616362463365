import React from 'react';
import OffersHeader from "../components/OffersHeader";
import OffersFooter from "../components/OffersFooter";

function Policy() {
	// const { t } = useTranslation();

	window.scroll(0, 0)

	// function createMarkup() {
	// 	return { __html: t('policy') }
	// }

	return (
		<>
			<OffersHeader />
			<div className='policy'>
				<div className="container">
					{/*<div className="policy__wrapper" dangerouslySetInnerHTML={createMarkup()}/>*/}
					<div className='policy__wrapper'>
						<h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>

						<h2>1. Общие положения</h2>
						<p>1.1. Настоящая политика (далее Политика конфиденциальности) обработки персональных данных
						   составлена в соответствии с требованиями Закона Республики Казахстан от 21 мая 2013 года №
						   94-V «О персональных данных и их защите».</p>
						<p>1.2. Политика конфиденциальности устанавливает правила использования онлайн-сервиса
						   Tateshka.kz Товарищество с ограниченной ответственностью «Эксперт Лидс Групп» (БИН
						   230140034639) (далее - Оператор) персональной информации, получаемой от пользователей (Далее
						   - Пользователь) сайта https://tateshka.kz (далее - Сайт).</p>
						<p>1.3. Текст политики доступен Пользователю в сети Интернет по сетевому адресу
						   https://tateshka.kz/policy. Использование Сайта
						   означает выражение Пользователем безоговорочного согласия с Политикой конфиденциальности и
						   указанными в ней условиями обработки его персональных данных. В случае несогласия с условиями
						   Политики конфиденциальности Пользователь должен прекратить использование Сайта.</p>
						<p>1.4. Политика конфиденциальности применяется только к сайту. Сайт не контролирует и не несет
						   ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам,
						   доступным на сайте.</p>
						<p>1.5. Оператор не проверяет достоверность персональных данных, предоставляемых Пользователем
						   Сайта, не контролирует, обладает ли Пользователь в момент использования Сайта дееспособностью
						   и исходит из того, что Пользователь предоставляет достоверную персональную информацию в
						   случае авторизации /регистрации на Сайте.</p>

						<h2>2. Определения</h2>
						<ol>
							<li><strong>День</strong> – 24 часа;</li>
							<li><strong>Учетная запись</strong> - совокупность данных, относящихся к конкретному
																Пользователю, включая информацию о его действиях на
																Веб-сайте и информацию, предоставленную Пользователем на
																Веб-сайте. Правила относительно Учетной записи изложены
																в пункте 3 Правил;
							</li>
							<li><strong>Регистрация</strong> - процесс создания Учетной записи Пользователем после
															 предоставления им соответствующих данных, принятия Правил и
															 активации Учетной записи;
							</li>
							<li><strong>Сайт (Сервис)</strong> - онлайн-платформа доступная на домене
															   https://tateshka.kz;
							</li>
							<li><strong>Пользователь</strong> - физическое, юридическое лицо или обособленное
															  подразделение, наделенное правоспособностью и использующее
															  Веб-сайт после входа в Учетную запись;
							</li>
							<li><strong>Индекс финансового здоровья</strong> - расчетный показатель кредитоспособности
																			 физического лица, позволяющая оценить его
																			 возможность получить финансовые услуги в
																			 той или иной организации.
							</li>
							<li><strong>Биометрические данные</strong> - персональные данные, которые характеризуют
																	   физиологические и биологические особенности
																	   субъекта персональных данных, на основе которых
																	   можно установить его личность;
							</li>
							<li><strong>Оператор</strong> - юридическое лицо Товарищество с ограниченной
														  ответственностью «Эксперт Лидс Групп» (БИН 230140034639),
														  организующее и осуществляющее обработку персональных данных, а
														  также определяющее цели обработки персональных данных, состав
														  персональных данных, подлежащих обработке, действия
														  (операции), совершаемые с персональными данными;
							</li>
							<li><strong>Персональные данные</strong> - любая информация, относящаяся Пользователю, как к
																	 субъекту персональных данных, предоставляемых
																	 Пользователем Оператору при переходе на Сайт,
																	 регистрации и/или авторизации на Сайте и
																	 использовании Сайта;
							</li>
							<li><strong>Обработка персональных данных</strong> - любое действие (операция) или
																			   совокупность действий (операций),
																			   совершаемых с использованием средств
																			   автоматизации или без использования таких
																			   средств с персональными данными, включая
																			   сбор, запись, систематизацию, накопление,
																			   хранение, изменение, дополнение,
																			   использование, распространение, уточнение
																			   (обновление, изменение), извлечение,
																			   использование обезличивание, блокирование
																			   и уничтожение персональных данных.
							</li>
							<li><strong>Конфиденциальность персональных данных</strong> - обязательное для соблюдения
																						Оператором или иным получившим
																						доступ к персональным данным
																						лицом требование не допускать их
																						распространения без согласия
																						субъекта персональных данных или
																						наличия иного законного
																						основания.
							</li>
							<li><strong>Конфиденциальная информация</strong> - сведения о лицах, предметах, фактах,
																			 событиях, явлениях и процессах, независимо
																			 от формы их представления, со специальным
																			 режимом защиты.
							</li>
							<li><strong>Согласие Пользователя на обработку персональных данных</strong> - (далее
																										Согласие)
																										выражение воли
																										Пользователя на
																										обработку
																										персональных
																										данных.
							</li>
							<li><strong>Cookies</strong> - небольшой фрагмент данных, отправленный веб-сервером и
														 хранимый на компьютере Пользователя, который веб-клиент или
														 веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе
														 при попытке открыть страницу соответствующего сайта.
							</li>
							<li><strong>IP-адрес</strong> - уникальный сетевой адрес узла в компьютерной сети,
														  построенной по протоколу IP.
							</li>
						</ol>

						<h2>3. Предмет политики конфиденциальности</h2>
						<p>3.1. Политика конфиденциальности устанавливает обязательства Оператора по неразглашению и
						   обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь
						   предоставляет по своей собственной воле Оператору при авторизации /регистрации на Сайте.</p>
						<p>3.2. Персональные данные, разрешённые к обработке в рамках Политики конфиденциальности,
						   предоставляются Пользователем путём формирования запроса способом, предложенным Оператором
						   или предоставления Оператору любым другим способом электронной передачи данных и могут
						   включать в себя следующую информацию:</p>
						<ul>
							<li>фамилия, имя, отчество, дата и место рождения, гражданство;</li>
							<li>вид, серия, номер документа, удостоверяющего личность, дата выдачи, наименование органа,
								выдавшего его;
							</li>
							<li>адрес регистрации и фактического проживания;</li>
							<li>ИИН (Индивидуальный идентификационный номер);</li>
							<li>банковские реквизиты (в том числе реквизиты банковских карт);</li>
							<li>номер телефона;</li>
							<li>адрес электронной почты;</li>
							<li>кредитная история;</li>
							<li>дата и данные о рождении;</li>
							<li>наличие и группа инвалидности и степени ограничения к трудовой деятельности;</li>
							<li>данные о социальных льготах и социальном статусе;</li>
							<li>сведения о дееспособности;</li>
							<li>портретное изображение (фотография);</li>
							<li>пол;</li>
							<li>подпись;</li>
							<li>банковские реквизиты (номер банковского счета, наименование и БИК банка);</li>
							<li>биографические данные;</li>
							<li>любые анкетные данные;</li>
							<li>данные водительского удостоверения;</li>
							<li>документы, подтверждающие право собственности или иное законное право на имущество, в
								том числе фотографии в любом формате имущества, идентификационные данные;
							</li>
							<li>данные о регистрации, обременении, общие характеристики имущества; стоимость, адрес
								(место нахождения) имущества, данные государственной регистрации и т.п.;
							</li>
							<li>сведения для поддержания связи, и документы, подтверждающие указанные сведения: данные о
								фактическом месте жительства; данные об адресе места регистрации;
							</li>
							<li>контактная информация
								(номера телефонов, адрес электронной почты и т.п.); документы, подтверждающие законность
								нахождения на территории Республики Казахстан и т.п.;
							</li>
							<li>данные о завершенных и (или) завершаемых уровнях образования (в том числе начальное;
								основное среднее; среднее образование (общее среднее, техническое и профессиональное);
								после среднее; высшее; послевузовское); ученая степень, ученое звание; владение языками;
								данные о повышении квалификации и переподготовке и т.п.;
							</li>
							<li>сведения о трудовой и (или) профессиональной деятельности, деловой репутации и
								документы, подтверждающие указанные сведения: общий стаж работы; трудовая книжка;
								трудовой (ые) договор (ы) с , о дате и основании его (их) прекращения;
							</li>
							<li>выписки из актов, подтверждающих возникновение, изменение и прекращение трудовых
								отношений на основе заключения, изменения и прекращения трудового (ых) договора (ов);
							</li>
							<li>послужной список (перечень сведений о работе, трудовой деятельности Пользователя);</li>
							<li>сведения из архивных учреждений или с мест работы; сведения о заработной плате и (или) о
								перечисленных обязательных пенсионных взносах, произведенных социальных отчислениях,
								налогообложении;
							</li>
							<li>сведения о размере, основаниях и лицах, имеющих право на удержание из заработной платы;
								сведения о награждении, поощрении, дисциплинарных взысканиях; членство в
								профессиональных палатах и (или) организациях;
							</li>
							<li>данные о наличии лицензий, аттестатов, патентов, сертификатов, удостоверений;</li>
							<li>наличие и (или) отсутствие судимости;</li>
							<li>привлечение к уголовной и (или) административной ответственности и т.п.</li>
							<li>сведения о семейном положении и документы, подтверждающие указанные сведения: сведения о
								заключении и (или) расторжении брака;
							</li>
							<li>иные сведения о регистрации актов гражданского состояния;</li>
							<li>сведения о наличии и (или) отсутствии иждивенцев и (или) иных членов семьи; степень
								родства;
							</li>
							<li>персональные данные иждивенцев и (или) иных членов семьи;</li>
							<li>данные об опекунстве;</li>
							<li>место работы или обучения членов семьи, и иждивенцев и т.п.;</li>
							<li>данные о пользовательском устройстве (среди которых разрешение, версия и другие
								атрибуты, характеризуемые пользовательское устройство, IP-адрес);
							</li>
							<li>информация о браузере пользователя (или иной программе, с помощью которой осуществляется
								доступ в сеть Интернет), технические характеристики оборудования и программного
								обеспечения, используемых пользователем;
							</li>
							<li>дата и время захода;</li>
							<li>пользовательские клики, просмотры страниц, заполнения полей, показы и просмотры баннеров
								и видео;
							</li>
							<li>данные, характеризующие аудиторные сегменты;</li>
							<li>параметры сессии;</li>
							<li>данные о времени посещения;</li>
							<li>идентификатор пользователя, хранимый в cookie.</li>
						</ul>
						<h2>4. Цели сбора персональной информации пользователя</h2>
						<p>Персональные данные Пользователя Сайта, Оператор может использовать в целях:</p>
						<ul>
							<li>Идентификации Пользователя, зарегистрированного на сайте Оператора, для оформления
								заявки и (или) оформления сделки дистанционным способом с Оператором.
							</li>
							<li>Предоставления Пользователю доступа к персонализированным ресурсам Сайта Оператора.</li>
							<li>Установления с Пользователем обратной связи, включая направление уведомлений, запросов,
								касающихся использования Сайта Оператора, оказания услуг, обработка запросов и заявок от
								Пользователя.
							</li>
							<li>Определения места нахождения Пользователя для обеспечения безопасности, предотвращения
								мошенничества.
							</li>
							<li>Подтверждения достоверности и полноты персональных данных, предоставленных
								Пользователем.
							</li>
							<li>Создания учетной записи для получения услуг Оператора. При этом Пользователь дает
								согласие на создание учетной записи создавая личный кабинет на сайте Оператора.
							</li>
							<li>Уведомления Пользователя Сайта Оператора о состоянии оформлении услуг Оператора.</li>
							<li>Уведомления Пользователя согласно предоставляемых услуг Оператора.</li>
							<li>Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания
								платежа, определения кредитоспособности Пользователя.
							</li>
							<li>Предоставления Пользователю эффективной клиентской и технической поддержки при
								возникновении проблем, связанных с использованием Сайта Оператора.
							</li>
							<li>Предоставления Пользователю обновлений продукции, специальных предложений, информации о
								ценах, новостной рассылки и иных сведений от имени Оператора или от имени партнеров
								Оператора.
							</li>
							<li>Осуществления рекламной деятельности.</li>
							<li>Предоставления доступа Пользователю на сайты или сервисы партнеров Оператора с целью
								получения продуктов, обновлений и услуг.
							</li>
						</ul>
						<h2>5. Порядок сбора, обработки (хранения), передачи и других видов обработки персональных
							данных</h2>
						<p>5.1. Безопасность персональных данных, обрабатываемых Оператором, обеспечивается путем
						   реализации правовых, организационных и технических мер, необходимых для выполнения в полном
						   объеме требований действующего законодательства в области защиты персональных данных.</p>
						<p>5.2. Доступ к персональным данным в объёме необходимом для целей обработки персональных
						   данных имеют только уполномоченные лица Оператора, в служебные обязанности которых входит
						   обработка, хранение, передача, уничтожение персональных данных Пользователя.</p>
						<p>5.3. Оператор принимает необходимые меры для защиты персональной информации Пользователя от
						   неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования,
						   распространения, а также от иных неправомерных действий третьих лиц.</p>
						<p>5.4. Оператор совместно с Пользователем принимает все необходимые меры по предотвращению
						   убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных
						   данных Пользователя.</p>
						<p>5.5. Обработка персональных данных Пользователя осуществляется Оператором с использованием
						   средств автоматизации или без использования таких средств.</p>
						<p>5.6. Условием прекращения обработки персональных данных может являться отзыв Согласия, путем
						   направления Пользователем Оператору уведомления посредством электронной почты на электронный
						   адрес Оператора support@tateshka.kz, а также выявление неправомерной обработки персональных
						   данных.</p>
						<p>5.7. Оператор вправе передать персональную информацию пользователя третьим лицам в
						   случаях:</p>
						<ul>
							<li>Пользователь выразил свое Согласие на получение конкретного продукта уполномоченного
								лица, указанного на Сайте Оператора;
							</li>
							<li>По своему усмотрению, при необходимости вовлечения компетентных третьих лиц и/или
								партнеров Оператора;
							</li>
							<li>Передача предусмотрена казахстанским законодательством в рамках установленной
								законодательством процедуры;
							</li>
							<li>В целях обеспечения возможности защиты прав и законных интересов Оператора или третьих
								лиц и в случаях, когда пользователь нарушает Пользовательское соглашение.
							</li>
						</ul>
						<p>5.8. В отношении персональной информации Пользователя сохраняется ее конфиденциальность,
						   кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа
						   неограниченному кругу лиц.</p>

						<h2>6. Сведения о реализуемых требованиях к защите персональных данных</h2>
						<p>6.1. В соответствии с п. 5.3 Политики конфиденциальности Оператор при обработке персональных
						   данных с использованием средств автоматизации /без использования средств автоматизации
						   принимает меры. К мерам защиты относятся:</p>
						<ul>
							<li>назначение сотрудников, ответственных за организацию обработки и обеспечение
								безопасности персональных данных;
							</li>
							<li>издание локальных актов по вопросам обработки персональных данных, ознакомление с ними
								таких сотрудников;
							</li>
							<li>обеспечение физической безопасности помещений и средств обработки, пропускной режим,
								охрана, видеонаблюдение;
							</li>
							<li>ограничение и разграничение доступа сотрудников и иных лиц к персональным данным и
								средствам обработки, мониторинг действий с персональными данными;
							</li>
							<li>определение угроз безопасности персональных данных при их обработке, формирование на их
								основе моделей угроз;
							</li>
							<li>применение средств обеспечения безопасности (антивирусных средств, межсетевых экранов,
								средств защиты от несанкционированного доступа);
							</li>
							<li>учёт и хранение носителей информации, исключающее их хищение, подмену,
								несанкционированное копирование и уничтожение;
							</li>
							<li>резервное копирование информации для возможности восстановления;</li>
							<li>осуществление внутреннего контроля по соблюдению установленного порядка, проверка
								эффективности принятых мер, реагирование на инциденты;
							</li>
							<li>уничтожение бумажных документов с применением шредера;</li>
							<li>уничтожение персональных данных на электронных носителях путем стирания или
								форматирования носителя.
							</li>
						</ul>
						<p>6.2. В случаях, указанных в п. 5.6. Политики о конфиденциальности Оператор прекращает
						   обработку данных, уничтожает носители не позднее 20 (двадцать) дней со дня наступления таких
						   условий.</p>

						<h2>7. Обязательства и права сторон</h2>
						<h3>7.1. Пользователь обязан:</h3>
						<p>7.1.1. в случае заполнения анкетных, регистрационных форм предоставить о себе персональную
						   информацию и гарантировать, что такая информация является достоверной и принадлежит именно
						   ему;</p>
						<p>7.1.2. возместить Оператору в бесспорном порядке любой ущерб и убытки, возникшие вследствие
						   использования Пользователем чужих персональных данных;</p>
						<p>7.1.3. обновить, дополнить предоставленную информацию о персональных данных в случае
						   изменения данной информации;</p>
						<p>7.1.4. не раскрывать умышленно либо по неосторожности логин и пароль третьему лицу, за
						   наступление любых последствий с такими действиями несет ответственность в соответствии с
						   законодательством Республики Казахстан.</p>
						<p>7.1.5. Пользователь гарантирует, что:</p>

						<ul>
							<li>понимает и принимает все условия Политики конфиденциальности. Дает согласие Оператору на
								обработку своих персональных данных;
							</li>
							<li>понимает, что в целях получения продукта Оператора и (или) третьих лиц, указанных на
								Сайте Оператора, дает Согласие путем заполнения анкетных, регистрационных форм;
							</li>
							<li>понимает, что при обращении по телефону, указанному на Сайте Оператора в разделе,
								содержащего описание конкретного продукта дает Согласие;
							</li>
							<li>до дачи Согласия на получение продукта ознакомился с порядком его получения, перечнем
								лиц, непосредственно его предоставляющим;
							</li>
							<li>понимает, что Политика о конфиденциальности применяется только к Сайту Оператора. К
								ссылкам на сторонние сайты, содержащиеся на Сайте Оператора, Политика о
								конфиденциальности не применяется;
							</li>
							<li>осведомлен, что вся информация, материалы, продукты, размещенные на Сайте,
								предоставляются Оператором или уполномоченными на то лицами без взимания какой-либо
								платы с Пользователя.
							</li>
						</ul>

						<h3>7.2. Пользователь имеет право:</h3>
						<p>7.2.1. Предоставить информацию о персональных данных, необходимую для использования сервисов,
						   материалов, продуктов, размещенных на Сайте;</p>
						<p>7.2.2. получить сведения об обработке его персональных данных, уточнении этих персональных
						   данных, их блокировании или уничтожении в случае, если они являются неполными, устаревшими,
						   неточными, незаконно полученными или не могут быть признаны необходимыми для заявленной цели
						   обработки, а также принимать предусмотренные законом меры по защите своих прав;</p>
						<p>7.2.3. на защиту своих прав и законных интересов через уполномоченный орган по защите прав
						   субъектов персональных данных и судебную защиту;</p>
						<p>7.2.4. на оформление заявок, заполнения анкет, подписки на рассылку рекламных и
						   информационных материалов Оператора, написания комментариев и общений с Оператором способами,
						   предусмотренными на Сайте;</p>
						<p>7.2.5. отозвать свое согласие на обработку персональных данных;</p>

						<h3>7.3. Оператор обязан:</h3>
						<p>7.3.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 Политики
						   конфиденциальности;</p>
						<p>7.3.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без
						   предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен,
						   опубликование, либо разглашение иными возможными способами переданных персональных
						   данных;</p>
						<p>7.3.3. Пользователя, за исключением п. «Способы и сроки обработки персональной информации»
						   настоящей Политики Конфиденциальности;</p>
						<p>7.3.4. Принимать меры предосторожности для защиты конфиденциальности персональных данных
						   Пользователя согласно порядку, обычно используемого для защиты такого рода информации в
						   существующем деловом обороте;</p>
						<p>7.3.5. Осуществить блокирование персональных данных, относящихся к соответствующему
						   Пользователю, с момента обращения или запроса Пользователя или его законного представителя
						   либо уполномоченного органа по защите прав субъектов персональных данных на период проверки,
						   в случае выявления недостоверных персональных данных или неправомерных действий;</p>

						<h3>7.4. Оператор имеет право:</h3>
						<p>7.4.1. Отстаивать свои интересы в суде;</p>
						<p>7.4.2. предоставлять персональные данные Пользователя без его согласия третьим лицам, если
						   это предусмотрено действующим законодательством (налоговые, правоохранительные органы и
						   др.);</p>
						<p>7.4.3 предоставлять третьему лицу персональные данные Пользователя при условии
						   предварительного согласия последнего, и указания на странице Сайта Оператора информации, о
						   том, какой конкретный продукт представляется и каким уполномоченным третьим лицом;</p>
						<p>7.4.4. отказывать в предоставлении персональных данных в случаях, предусмотренных
						   законодательством.</p>

						<h2>8. Разрешение споров</h2>
						<p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта
						   и Оператором, обязательным является предъявление претензии (письменного предложения о
						   добровольном урегулировании спора).</p>
						<p>8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно
						   уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
						<p>8.3. При не достижении соглашения спор будет передан на рассмотрение в суде по
						   местонахождению Оператора в соответствии с действующим законодательством Республики
						   Казахстан.</p>
						<p>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором
						   (Администрацией Сайта) применяется действующее законодательство Республики Казахстан.</p>

						<h2>9. Дополнительные условия</h2>
						<p>9.1. Оператор вправе вносить изменения в настоящую Политику конфиденциальности без согласия
						   Пользователя. <br />Новая Политика конфиденциальности вступает в силу с момента ее размещения
						   на Сайте Оператора, если иное не предусмотрено новой редакцией Политики конфиденциальности.
						</p>
						<p>9.2. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать
						   Оператору на почту support@tateshka.kz <br />Действующая Политика конфиденциальности
						   размещена на странице по адресу https://tateshka.kz/policy</p>

						<h4>Текст настоящего пользовательского соглашения мной прочитан, с ним согласен, дополнений,
							замечаний и возражений не имею.</h4>
					</div>
				</div>
			</div>
			<OffersFooter />
		</>

	);
}

export default Policy;