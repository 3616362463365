export function generateRandomNumber(sector) {
	switch (sector) {
		case 1:
			return Math.floor(Math.random() * 17) + 1;
		case 2:
			return Math.floor(Math.random() * 17) + 18;
		case 3:
			return Math.floor(Math.random() * 18) + 35;
		case 4:
			return Math.floor(Math.random() * 18) + 53;
		case 5:
			return Math.floor(Math.random() * 15) + 71;
		case 6:
			return Math.floor(Math.random() * 15) + 86;
		default:
			return 0
	}
}