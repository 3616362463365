import React, { memo } from "react";
import Countdown from "react-countdown";
import { setLoginSmsStatus, setVerifySmsStatus } from "../redux/slices/authSlice";


// Renderer callback with condition
const CountDown = memo(({ dispatch, resetSmsCode }) => {

	const styles = {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '5px'
	}
	const renderer = (props) => {
		if (props.completed) {
			resetSmsCode('smsCode')
			dispatch(setLoginSmsStatus(''))
			dispatch(setVerifySmsStatus(''))
		} else {
			// Render a countdown
			return <span>{props.formatted.minutes}:{props.formatted.seconds}</span>;
		}
	}
	return <div style={styles}>
		<Countdown
			className='countdown'
			zeroPadTime={2}
			date={Date.now() + 120000}
			renderer={renderer} />
	</div>
})

export default CountDown
