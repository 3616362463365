import React from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IndexHealthCard } from "./index";
import { HOST } from "../api/apiUrls";

import visa from "../assets/images/methods/visa.png";
import mastercard from "../assets/images/methods/mastercard.png";
import bank from "../assets/images/methods/bank.png";
import mir from "../assets/images/methods/mir.png";


function Offer({ item, isLogined }) {
	const { t, i18n } = useTranslation()
	const { language } = i18n
	const { visitHash, applicationId } = useSelector(state => state.main)

	return <>
		{item.id === 'index' ?
			<IndexHealthCard />
			:
			<a target='_blank' rel="noreferrer"
			   href={`${HOST}offer/${isLogined ? `a/${applicationId}/` : `${visitHash}/`}${item.id}`}
			   className="offer">
				<div className="offer__logo">
					<img src={item.image} alt="offerLogo" />
				</div>
				<p className='offer__leader'>{item.tooltip[language]}</p>
				<div className="offer__conditions d-flex flex-column">
					<div className="condition">
						<p>{t('offers.sum')}</p>
						<p>{t('offers.to')} <span className='highlight-bg bold'>{item.sum}</span> ₸</p>
					</div>
					{item.percent && (
						<div className="condition">
							<p>{t('offers.percent')}</p>
							<p>
								<span className='highlight'><span className='bold'>{item.percent}</span>%</span> <span
								className='bold'>/</span> {t('offers.day')}
							</p>
						</div>
					)}
					{(item.age_from && item.age_to) && (
						<div className="condition">
							<p>{t('offers.age')}</p>
							<p>{t('offers.from')} <span
								className='highlight bold'>{item.age_from}</span> {t('offers.to')} <span
								className='highlight bold'>{item.age_to}</span> {t('offers.years')}
							</p>
						</div>
					)}
				</div>
				<div className="offer__methods">
					<p>{t('offers.method')}</p>
					<div className="methods__wrapper">
						<div>
							<img src={visa} alt="visa" />
						</div>
						<div>
							<img src={mastercard} alt="mastercard" />
						</div>
						<div>
							<img src={bank} alt="bank" />
						</div>
						<div>
							<img src={mir} alt="mir" />
						</div>
					</div>
				</div>

				<button className='getMoney'>{t('general.getMoney')}</button>
			</a>
		}
	</>
}

export default Offer;