import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CountDown from "../CountDown";
import Button from "../Button";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import { setApplicationId, setFormStep, setPhone } from "../../redux/slices/mainSlice";
import {
	fetchLoginSms,
	fetchVerifyLoginSms,
	setLoginSmsStatus,
	setVerifySmsStatus
} from "../../redux/slices/authSlice";

const LoginModal = ({ show, handleClose }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch()
	const { visitHash, phoneNumber, queryParams, redirectRules } = useSelector(state => state.main)
	const { loginSmsStatus, verifySmsStatus, isLoadingLogin } = useSelector(state => state.auth)


	const defaultValues = {
		number: '',
		smsCode: ''
	}
	const {
		formState: {
			errors
		},
		control,
		handleSubmit,
		resetField

	} = useForm({
		mode: 'onSubmit',
		defaultValues
	})

	const goToRegister = () => {
		const linkNoReg = redirectRules.noRegLandUrl ? `${redirectRules.noRegLandUrl}` : '/noregoffers'
		localStorage.setItem('isLogined', '')

		dispatch(setPhone(phoneNumber))
		dispatch(setFormStep(1))
		dispatch(setApplicationId(''))

		setTimeout(() => {
			window.open(`/${queryParams && `?${queryParams}`}`, '_blank')
		}, 100)
		setTimeout(() => {
			window.location.href = `${linkNoReg}${queryParams && `?${queryParams}`}`
		}, 300)
	}


	const onSubmitNumber = (data) => {
		const bodyFormData = new FormData();
		const phone = data.number.replace(/[ +()]/g, '')
		localStorage.setItem('isLogined', '')
		bodyFormData.append('phone', phone)
		bodyFormData.append('visitHash', visitHash)

		if (!data.smsCode) {
			dispatch(setPhone(phone))
			dispatch(fetchLoginSms(bodyFormData))
		} else {
			bodyFormData.append('smsCode', data.smsCode)
			dispatch(fetchVerifyLoginSms(bodyFormData))
		}
	}

	useEffect(() => {
		// make timer not to count from beginning
		dispatch(setLoginSmsStatus(''))
		dispatch(setVerifySmsStatus(''))
	}, [dispatch])


	return (
		<Modal backdrop="static" centered size='lg' show={show} onHide={handleClose}>
			<Modal.Header closeButton />
			<Modal.Body>
				<div className="body-title fs-4 text-center">
					{(loginSmsStatus === '' || loginSmsStatus === 'error') && t('general.enterNumberAndGetCode')}
					{loginSmsStatus === 'success' && t('general.smsWithCodeWasSent')}
				</div>
				{loginSmsStatus === 'success' && <CountDown dispatch={dispatch} resetSmsCode={resetField}/>}
				<form onSubmit={handleSubmit(onSubmitNumber)}>
					<div
						className={`input__block required ${loginSmsStatus === 'success' ? 'disabled' : ''}`}>
						<Controller
							className={"input__block"}
							name="number"
							rules={{
								pattern: {
									value: /^\+7 \((6|7)\d{2}\) \d{3} \d{2} \d{2}/,
									message: '+7 (6xx) xxx-xx-xx, +7 (7xx) xxx-xx-xx'
								},
								required: t('general.required')
							}}
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputMask mask="+7 (999) 999 99 99" maskChar=" " value={value}
										   onChange={onChange}>
									{(inputProps) => (
										<input
											placeholder={t('general.enterNumber')}
											{...inputProps}
											type="tel"
											className="input"
										/>
									)}
								</InputMask>
							)}
						/>
						<div className='error'>{errors?.number && <p>{errors?.number?.message}</p>}</div>

					</div>

					{loginSmsStatus === 'success' &&
						<div className="input__block required">
							<Controller
								className={"input__block"}
								name="smsCode"
								rules={{ required: t('general.required') }}
								control={control}
								render={({ field: { onChange, value } }) => (
									<input
										onChange={onChange}
										placeholder={t('general.enterCode')}
										type='number'
										className="input"
										value={value}
									/>
								)}
							/>
							<div className='error'>{errors?.smsCode &&
								<p>{errors?.smsCode?.message}</p>}</div>
						</div>
					}
					{loginSmsStatus === 'error' && (
						<div className='error__sms--status'>
							{t('general.numberIsNotRegistered')} <span
							onClick={goToRegister}>{t('general.registerNow')}</span>
						</div>
					)}
					{verifySmsStatus === 'error' && (
						<div className='error'>
							<p>{t('general.codeEnteredIncorrectly')}</p>
						</div>
					)}
					<Button className={`outlined orange ${isLoadingLogin ? 'disabled' : ''}`}>
						{t('general.sendSMS')}
					</Button>
				</form>


			</Modal.Body>
		</Modal>
	);
}

export default LoginModal;