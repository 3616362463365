import React, { useEffect, useState } from 'react';
import { Button } from "./index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { HOST } from "../api/apiUrls";

import kapustaLogo from "../assets/images/kapusta.png";


function Popup() {
    const { t } = useTranslation();
    const { visitHash, applicationId } = useSelector(state => state.main)
    const { isLogined } = useSelector(state => state.auth)


    const [show, setShow] = useState(false)

    const handleOpen = () => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 10000)
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        handleOpen();
    }, [])

    return (
        <>
            {
                show &&
                <div className="popup">
                    <div onClick={() => setShow(false)} className="popup__close">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="#D4D7FB" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round" />
                            <path d="M6 6L18 18" stroke="#D4D7FB" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round" />
                        </svg>
                    </div>

                    <div className="popup__logo">
                        <img src={kapustaLogo} alt="kapusta" />
                    </div>

                    <div className="popup__title">
                        <h2>
                            {t('popup.title')}
                        </h2>
                    </div>


                    <div className="popup__text">
                        {t('popup.text')}
                    </div>

                    <a target='_blank' rel="noreferrer"
                       href={`${HOST}offer/${isLogined ? `a/${applicationId}/` : `${visitHash}/`}1`}
                       className="popup__btn">
                        <Button className='outlined'>{t('general.getMoney')}</Button>
                    </a>

                </div>
            }
        </>
    );
}

export default Popup;