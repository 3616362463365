import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {kz, ru} from "../locale";


export const initTranslations = () => {
    const lang = localStorage.getItem('lang') || 'ru'

    return i18n
        .use(initReactI18next)
        .init({
            resources: {
                kz: {
                    translation: kz
                },
                ru: {
                    translation: ru
                }
            },
            fallbackLng: lang,

            interpolation: {
                escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            }
        });
}
