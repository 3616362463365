import React from 'react';
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import ModalStepOne from "./ModalStepOne";
import ModalStepTwo from "./ModalStepTwo";

function ModalComponent({ show, handleClose }) {
    const { formStep, applicationId } = useSelector(state => state.main)

    return (
        <Modal backdrop="static" centered size='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton={!!applicationId} />
            <Modal.Body>
                {formStep === 1 &&
                    <ModalStepOne />
                }

                {formStep === 2 &&
                    <ModalStepTwo />
                }
            </Modal.Body>
        </Modal>
    );
}

export default ModalComponent;